import cloneDeep from 'lodash/cloneDeep'
import findIndex from 'lodash/findIndex'
import isEmpty from 'lodash/isEmpty'
import isEqual from 'lodash/isEqual'
import useToggler from '../hooks/toggler.js'
import useApiErrors from '../hooks/apiErrors.js'
import { computed, reactive, watch } from '@vue/composition-api'
import { objectClear } from '../hooks/helpers.js'
import { useRouter } from '../hooks/router.js'
import { useActions } from '../hooks/store.js'

export default function useApiCrud(
  {
    api,
    ctx,
    events = {},
    filters = {},
    baseParams = {},
    methods = {},
    routed = true,
    routes = {},
    dispatchErrors = true,
    watchDirty = true,
    immediate = true,
  },
  data = {}
) {
  const errors = useApiErrors({ dispatch: dispatchErrors })
  const router = useRouter()

  const {
    toggler: destroing,
    on: showDestroing,
    off: hideDestroing,
  } = useToggler()
  const { toggler: loading, on: showLoader, off: hideLoader } = useToggler()
  const { toggler: saving, on: showSaving, off: hideSaving } = useToggler()

  /**
   * Destroy api call
   *
   * @param  mixed id
   * @return Promise
   */
  function apiDestroy(id) {
    //method ovveride
    if (methods.apiDestroy) {
      return methods.apiDestroy(id)
    }

    showDestroing()
    return api
      .destroy(id)
      .then((response) => {
        errors.clearError()

        close()

        //trigger onDestroy
        if (events.destroy) {
          response = events.destroy(response)
          if (response == false) return response
        }

        return response
      })
      .catch(apiError)
      .finally(hideDestroing)
  }

  /**
   * Api set error
   *
   * @param  Exception response
   * @return Promise
   */
  function apiError(response) {
    //trigger onload
    if (events.error) {
      response = events.error(response)
      if (response == false) return response
    }

    errors.setError(response)

    return response
  }

  /**
   * Index api call
   *
   * @param  mixed request params
   * @return Promise
   */
  function apiIndex(params) {
    //method ovveride
    if (methods.apiIndex) {
      return methods.apiIndex(params)
    }

    showLoader()
    return api
      .index({ ...baseParams, ...params })
      .then((response) => {
        errors.clearError()

        //trigger onload
        if (events.beforeSetIndex) {
          response = events.beforeSetIndex(response)
          if (response == false) return response
        }

        state.data = response.data
        state.meta = response.meta

        //trigger onload
        if (events.index) {
          response = events.index(response)
          if (response == false) return response
        }

        return response
      })
      .catch(apiError)
      .finally(hideLoader)
  }

  /**
   * Show api call
   *
   * @param  mixed id
   * @return Promise
   */
  function apiShow(id) {
    //method ovveride
    if (methods.apiShow) {
      return methods.apiShow(id)
    }

    showLoader()
    return api
      .show(id)
      .then((response) => {
        errors.clearError()

        //trigger onShow
        if (events.show) {
          response = events.show(response)
          if (response == false) return response
        }

        setDetail(response.data)

        return response
      })
      .catch(apiError)
      .finally(hideLoader)
  }

  /**
   * Store api call
   *
   * @param  mixed data
   * @return Promise
   */
  function apiStore(data) {
    //method ovveride
    if (methods.apiStore) {
      return methods.apiStore(data)
    }

    showSaving()
    return api
      .store(data)
      .then((response) => {
        errors.clearError()

        //trigger onStore
        if (events.store) {
          response = events.store(response)
          if (response == false) return response
        }

        //trigger onSave
        if (events.save) {
          response = events.save(response)
          if (response == false) return response
        }

        setDetail(response.data, false)

        return response
      })
      .catch(apiError)
      .finally(hideLoader)
  }

  /**
   * Update api call
   *
   * @param  mixed id
   * @param  mixed data
   * @return Promise
   */
  function apiUpdate(id, data) {
    //method ovveride
    if (methods.apiUpdate) {
      return methods.apiUpdate(id, data)
    }

    showSaving()
    return api
      .update(id, data)
      .then((response) => {
        errors.clearError()

        //trigger onUpdate
        if (events.update) {
          response = events.update(response)
          if (response == false) return response
        }

        //trigger onSave
        if (events.save) {
          response = events.save(response)
          if (response == false) return response
        }

        setDetail(response.data, false)

        return response
      })
      .catch(apiError)
      .finally(hideSaving)
  }

  /**
   * Close detail
   *
   * @return void
   */
  function close() {
    navigate(null)
  }

  /**
   * Remove detail
   *
   * @param  mixed id
   * @return void
   */
  function destroy(id = null) {
    navigate(id || state.detail.id, 'destroy')
  }

  /**
   * Edit detail
   *
   * @param  mixed id
   * @return void
   */
  function edit(id = null) {
    navigate(id || state.detail.id, 'edit')
  }

  /**
   * Index load
   *
   * @param  mixed params to merge in filters
   * @return void
   */
  function index(params = {}) {
    state.filters = cloneDeep({ ...state.filters, ...params })
  }

  /**
   * Set detail data
   *
   * @param  object value
   * @param  bool copyToEditable
   * @return void
   */
  function setDetail(value = {}, copyToEditable = true) {
    //trigger onDetail
    if (events.detail) {
      value = events.detail(value)
    }

    state.detail = !isEmpty(value)
      ? Object.assign({}, state.detail, cloneDeep(value))
      : {}
    //editable sync
    setEditable(value, copyToEditable)
  }

  /**
   * Set editable data
   *
   * @param  object value
   * @return void
   */
  function setEditable(value = {}, override = true) {
    if (isEmpty(value)) {
      state.editable = {}
      return
    }

    if (override) {
      state.editable = Object.assign({}, state.editable, cloneDeep(value))
      return
    }

    //else extend
    state.editable = Object.assign(state.editable, cloneDeep(value))
  }

  /**
   * Navigation
   *
   * @param  mixed id
   * @param  mixed action
   * @param  Object query
   * @param  Object params
   * @return void
   */
  function navigate(id, action = null, query = {}, params = {}) {
    //ruoted navigation
    if (routed) {
      query = objectClear({ ...ctx.root.$route.query, ...query })

      const routeData = {}
      if (!isEmpty(routes)) {
        if (routes[action] !== undefined) {
          routeData.name = routes[action]
        } else {
          if (id != null) {
            if (id == 'create' && routes['create'] !== undefined) {
              routeData.name = routes['create']
            } else if (routes['show'] !== undefined) {
              routeData.name = routes['show']
            }
          }
          //default index
          if (routeData.name == undefined && routes['index'] !== undefined) {
            routeData.name = routes['index']
          }
        }
      }

      router
        .push({
          ...routeData,
          params: {
            id: id,
            action: action,
            ...params,
          },
          query,
        })
        .catch(() => {})
    } else {
      goNavigate(id, action, params)
    }
  }

  /**
   * Perform requested navigation
   *
   * @param  mixed id
   * @param  mixed action
   * @return void
   */
  function goNavigate(id, action = null, params = {}) {
    //id
    if (id) {
      if (state.detail.id != id) {
        apiShow(id)
      }
    } else {
      setDetail({})
    }

    //action
    state.action = action

    //check action
    switch (state.action) {
      case 'edit':
        //reset editable object
        if (state.detailDirty) {
          setEditable(state.detail)
        }
        break
    }

    //filters
    state.filters = cloneDeep({ ...state.filters, ...params })
  }

  /**
   * Show detail
   *
   * @param  mixed id
   * @return void
   */
  function show(id = null) {
    navigate(id || (state.detailCreate ? null : state.detail.id))
  }

  /**
   * Update or store, base on detail.id
   *
   * @return void
   */
  function save() {
    if (state.editable.id != 0) {
      update()
    } else {
      store()
    }
  }

  /**
   * Store new item
   *
   * @return void
   */
  function store() {
    const data = events.beforeStore
      ? events.beforeStore(state.editable)
      : { ...state.editable }

    if (data === false) return

    apiStore(data)
  }

  /**
   * Update editable item
   *
   * @return void
   */
  function update() {
    //trigger onBeforeStore
    const data = events.beforeUpdate
      ? events.beforeUpdate(state.editable)
      : { ...state.editable }

    if (data === false) return

    apiUpdate(state.editable.id, data)
  }

  /**
   * Index of the selected item in the collection data
   *
   * @return int Index
   */
  const detailIndex = computed(() => {
    if (state.data.length && state.detailShow) {
      //new, -1
      if (state.detailCreate) {
        return -1
      }

      return findIndex(state.data, (item) => item.id == state.detail.id)
    }

    return 0
  })

  /**
   * Detail status computed
   *
   * @return boolean
   */
  const detailCreate = computed(() => state.detail.id === 0)
  const detailDestroy = computed(() => state.action == 'destroy')
  const detailDirty = computed(
    () => state.detailEdit && !isEqual(state.detail, state.editable)
  )
  const detailEdit = computed(
    () =>
      (state.action == 'edit' || state.detailCreate) &&
      state.editable.id !== undefined
  )
  const detailShow = computed(() => state.detail.id !== undefined)

  /**
   * Ractive data
   */
  const state = reactive({
    action: '',
    data: [],
    meta: [],
    detail: {},
    detailCreate,
    detailDestroy,
    detailDirty,
    detailEdit,
    detailIndex,
    detailShow,
    editable: {},
    filters,
    indexParams: computed(() => objectClear({ ...state.filters })),
    ...data,
  })

  if (watchDirty) {
    const { setDirty } = useActions(['setDirty'])
    watch(
      () => state.detailDirty,
      (value) => {
        setDirty(value)
      }
    )
  }

  /**
   * Routed navigation / filtering
   */
  if (routed) {
    let isFromHistoryNavigation = false

    watch(
      () => state.filters,
      (value) => {
        //check if is from navigation, skip renavigate
        if (!isFromHistoryNavigation) {
          const { id, action } = ctx.root.$route.params
          //push
          navigate(id, action, value)
        }

        //reset
        isFromHistoryNavigation = false
      },
      {
        deep: true,
      }
    )

    watch(
      () => ctx.root.$route,
      (value, preValue) => {
        //set from navigation
        isFromHistoryNavigation = true

        //perform
        goNavigate(value.params.id, value.params.action, value.query)

        //list reload check
        if (!state.data.length || !isEqual(value.query, preValue.query)) {
          apiIndex(state.indexParams)
        }
      },
      {
        immediate,
      }
    )
  }

  return {
    //data
    state,
    //methods
    setDetail,
    setEditable,
    //status
    destroing,
    errors,
    loading,
    saving,
    //route methods
    navigate,
    close,
    //api methods
    apiDestroy,
    apiIndex,
    apiShow,
    apiStore,
    apiUpdate,
    //crud methods
    destroy,
    edit,
    index,
    save,
    show,
    store,
    update,
  }
}
